'use client';

import type { FC, PropsWithChildren } from 'react';
import AppProgressBar from '~components/loading/transition-progress-bar';

const NprogressLoading: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {children}
      <AppProgressBar color="#ECDC89" height="4px" />
    </>
  );
};

export default NprogressLoading;
