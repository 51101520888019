'use client';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import appConfig from '~build-config/config.json';
import LazyImportPlugin from '~i18n/lazy-load-plug-in-i18n';

void i18n
  .use(initReactI18next)
  .use(LazyImportPlugin)
  .init({
    ...appConfig.i18n,
    debug: false,
    load: 'languageOnly',
    preload: [],
    ns: ['errors', 'profile'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
