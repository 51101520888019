'use client';

import { SessionProvider } from 'next-auth/react';
import type { FC, ReactNode } from 'react';

interface NextAuthProviderProps {
  children?: ReactNode;
  session: any;
}

export const NextAuthProvider: FC<NextAuthProviderProps> = ({
  children,
  session,
}) => {
  return (
    <SessionProvider
      basePath="/next/api/auth"
      refetchInterval={0}
      refetchOnWindowFocus
      session={session}
    >
      {children}
    </SessionProvider>
  );
};
