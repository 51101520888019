'use client';

import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { cn } from 'class-merge';

const I18nProvider: FC<{
  children: ReactNode;
  locale: string;
}> = ({ children, locale }) => {
  const { i18n } = useTranslation();
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    void i18n.changeLanguage(locale).then(() => {
      setReady(true);
    });
  }, [locale]);

  return (
    <div
      className={cn(
        'opacity-0 transition-opacity duration-150',
        ready && 'opacity-100',
      )}
    >
      {children}
    </div>
  );
};

export default I18nProvider;
